/**
 * Footer Manipulation
 *
 * @since 1.0.0
 * @package skydeck
 */
(function($) {
	$('footer .footer-content .col-2 .socials').wrapAll('<li id="footer-social"></li>');
	$('#footer-social').appendTo('#footer-menu');

	// Run code only on Project Category page 
	if ( $('.featured-project').length > 0 ) {
		var url_array = window.location.href.split('/');
		// Assumes URLs end with '/' causing last item to be ''
		var current_category = url_array[url_array.length-2];
		// Return Nodelist object
		var featured_projects = $('.featured'); //document.getElementsByClassName('featured');
		var fp_count = featured_projects.length;

		// Traverse Featured Projects
		for (var i=0; i<fp_count; i++) {
			// No category chosen: break
			if ( current_category.includes('projects') ) {
				break;
			}
			else {
				var isMatch = featured_projects[i].getAttribute('data-category').includes(current_category);

				// Non-IE9 approach
				if ( featured_projects[i].classList ) {
					if ( isMatch ) {
						featured_projects[i].classList.remove('hide');
						continue;
					}
					else {
						featured_projects[i].classList.add('hide');
					}
					// No matches? : unhide default Featured Project
					if ( i == (fp_count-1) && $('.featured:not(.hide)').length <= 0 ) {
						if ( $('.default').length > 0 )
							 $('.default')[0].classList.remove('hide');
					}
				}
				// For IE9
				else {
					var classes = featured_projects[i].className.split(' ');
					var index = classes.indexOf('hide');

					// If match and hidden -> show
					if (isMatch && index >= 0) {
						classes.splice(index, 1);
					}
					// If no match and not hidden -> hide
					else if (!isMatch && index < 0) {
						classes.push('hide');
					}
					featured_projects[i].className = classes.join(' ');

					// No matches? : unhide default Featured Project
					if ( i == (fp_count-1) && $('.featured-project').not('.hide').length == 0 ) {
						var defaultElement =  $('.default')[0];
						classes = defaultElement.className.split(' ');
						index = classes.indexOf('hide');
						if ( index >= 0 )
							 classes.splice(index, 1);
						defaultElement.className = classes.join(' ');
					}
				}
			}
		}
	}

	// Run code only on Project Category page
	if ( $('.project-grid').length > 0 ) {
		var featured_project = $('.featured').not('.hide').find('h2');

		var other_projects = $('.project-grid .card-wrapper');
		var other_projects_h2 = $('.project-grid .card-wrapper').find('h2');

		// Check featured project for this category exists
		if ( featured_project.length > 0 && featured_project[0].textContent !== '' ) {
			for (var i=0; i<other_projects.length; i++) {

				// Hide if duplicate project
				if ( featured_project[0].textContent.toLowerCase() == other_projects_h2[i].textContent.toLowerCase() ) {
					
					if ( other_projects[i].classList ) {
						other_projects[i].classList.add('hide');
					}
					// For IE9
					else {
						var classes = other_projects[i].classname.split(' ');
						var index = classes.indexOf('hide');

						if ( index >= 0 ) { classes.splice(index, 1); }
						else { classes.push('hide'); }

						other_projects[i].className = classes.join(' ');
					}
				}
			} // for
		}
	}

	// Run code only on Services page
	// 
	if ( $('body.post-type-archive-ia_service').length > 0 ) {
		var featuredService = $('.featured-service');
		var services = $('.service-grid a').not( featuredService );

		for(var i=0; i<services.length; i++) {

			if ( services[i].href.toLowerCase() === featuredService[0].href.toLowerCase() ) {
				services[i].style.display = 'none'
			}
		}

	}

	// Run code only on Portfolio page :
	// Links from Home page sliders cause corresponding Portfolio item modal to auto-display
	if ( $('[data-tag]').length > 0 ) {

		// Get query arg from URL, match with data-tag, open featherlight
		var urlParams = new URLSearchParams( window.location.search );

		if ( urlParams.has('item') ) {
			var selector = '[data-tag=' + urlParams.get('item') + ']';
			var triggerElement = $( selector )
			var id = $( selector ).attr( 'href' );
			var lightboxElement = $( id );

			// Click required to trigger featherlight AND flickity carousel
			//$.featherlight( lightboxElement );
			triggerElement.click();
		}
	}





})(jQuery);