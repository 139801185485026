/**
 * Captcha non scroll on ios fix
 *
 * @since 1.0.0
 * @package skydeck
 */

// reCAPTCHA div container ID is hard coded below as input_1_12
window.scrollCaptcha = false;

//get the gravity forms field id that contains the captcha
var captcha = document.getElementById('input_1_12');

if (captcha !== null) {

	captcha.setAttribute('data-callback','scrollFixCaptcha');
	function scrollFixCaptcha(a) { if(window.scrollCaptcha) { jQuery("html, body").scrollTop(window.scrollCaptcha); } }
	document.addEventListener("scroll", function() {
		var el = document.getElementById('input_1_12');
		var theTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		var elemTop = el.getBoundingClientRect().top;
		var elemBottom = el.getBoundingClientRect().bottom;
		var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
		if(isVisible) { window.scrollCaptcha = theTop;  }
	});

}
